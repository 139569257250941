body {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
